import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '@shared/services/common.service';
import { MultiSummaryService } from '@shared/services/multi-summary.service';
import { LocalSourcesService } from '@shared/services/local-sources.service';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { MatDialog } from '@angular/material/dialog';
import { SourceSearchModalComponent } from '../../../components/modals/source-search-modal/source-search-modal.component';
import { Subscription } from 'rxjs';

type FileErrorType = 'size' | 'ext' | 'maxInput';

@Component({
  selector: 'app-conversation-input',
  templateUrl: './conversation-input.component.html',
  styleUrls: ['./conversation-input.component.scss'],
})
export class ConversationInputComponent implements OnInit, OnDestroy {
  @Input() isWriting = false;
  @Input() allowAttachFile = true;
  @Input() isDisabled = false;
  @Input() isExtended = false;
  @Input() filterButton = false;
  @Input() fixedToBottom = false;
  @Input() promptHints: string[] = [];
  protected filtersAreOpened = false;
  protected selectedSources: TSourceItem[] = [];

  private _botId = '';
  private _subs: Subscription[] = [];

  @Input() set botId(value: string) {
    this._botId = value;
    if (value) {
      this.loadSources();
    }
  }

  get botId(): string {
    return this._botId;
  }

  @Input() set message(msg: string) {
    this.updateMessage(msg);
  }

  @Output() messageSubmitted = new EventEmitter<{
    message: string;
    files: any[];
    sourceIds: string[];
  }>();
  @Output() writingAbort = new EventEmitter();
  @Output() filtersClick = new EventEmitter<boolean>();

  protected defaultPlaceholder = 'Enter your text here or drop files to attach';
  protected placeholder = this.defaultPlaceholder;
  protected isDragging = false;
  protected fileValidationParameters = {
    allowedExt: ['pdf', 'txt', 'csv', 'docx', 'pptx', 'rtf', 'html', 'css', 'png', 'jpg'],
    maxInputFiles: 5,
    maxFileSizeMB: 10,
  };

  protected errorsDictionary: { [key in FileErrorType]: string } = {
    size: 'file is to big',
    ext: 'unsupported extension',
    maxInput: 'maximum number of files reached',
  };

  private _filesErrors: Set<FileErrorType> = new Set<FileErrorType>();

  protected isInputFocused = false;
  protected files: File[] = [];

  protected hasSummary = false;

  constructor(
    private _fb: FormBuilder,
    private _commonService: CommonService,
    private _multiSummaryService: MultiSummaryService,
    protected _localSourcesService: LocalSourcesService,
    private _dialog: MatDialog
  ) {
    this._multiSummaryService
      .getVisibilityState()
      .subscribe(isVisible => (this.hasSummary = isVisible));
  }

  ngOnInit() {
    if (this.botId) {
      this.loadSources();
    }
  }

  private loadSources() {
    this._subs.push(
      this._localSourcesService.getItems(this.botId).subscribe(sources => {
        this.selectedSources = sources;
      })
    );
  }

  public messagesForm = this._fb.nonNullable.group({
    message: ['', [Validators.required, Validators.minLength(1)]],
  });

  protected updateMessage(msg: string | null) {
    if (msg === null) {
      this.messagesForm.controls.message.reset();
    } else {
      this.messagesForm.controls.message.patchValue(msg);
    }
  }

  onFileSelected(event: any) {
    this.handleFileInput(event.target.files);
  }

  handleFileInput(files: File[]) {
    this._filesErrors.clear();
    for (let i = 0; i < files.length; i++) {
      if (this.validateFile(files[i])) {
        const file = files[i];
        if (file) {
          this.files.push(file);
        }
      }
      if (this.filesLeftQty === 0) {
        if (files.length - i - 1 > 0) {
          // check if there are items left
          this._filesErrors.add('maxInput');
        }
        break;
      }
    }
    this.showErrors();
  }

  get filesLeftQty(): number {
    return this.fileValidationParameters.maxInputFiles - this.files.length;
  }

  protected validateFile(file: File) {
    const fileExt = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
    if (!this.fileValidationParameters.allowedExt.includes(fileExt)) {
      this._filesErrors.add('ext');
      return false;
    }
    if (file.size / 1024 > this.fileValidationParameters.maxFileSizeMB * 1024) {
      this._filesErrors.add('size');
      return false;
    }
    return true;
  }

  protected showErrors() {
    if (this._filesErrors.size) {
      let error = 'Some files were not added due to errors: ';
      error += Array.from(this._filesErrors)
        .map(type => this.errorsDictionary[type])
        .join(', ')
        .concat('.');
      this._commonService.openSnackBar(error, 'Close', 5000);
    }
  }

  onEnter(event: Event, submit: boolean) {
    event.preventDefault();
    if (submit) {
      this.submit();
    } else {
      const currentText = this.messagesForm.controls.message.value;
      this.updateMessage(currentText + '\n');
    }
  }

  submit() {
    this.messageSubmitted.emit({
      message: this.messagesForm.controls.message.value,
      files: this.files,
      sourceIds: this.selectedSources.map(source => source._id)
    });
    this.files = [];
    this.setPlaceholder();
    this.updateMessage('');
    this.filterButton = false;
    this.toggleFilters(false);
  }

  removeFile(i: number) {
    this.files.splice(i, 1);
  }

  setPlaceholder(placeholder: string | null = null) {
    this.placeholder = placeholder || this.defaultPlaceholder;
  }

  toggleFilters(force?: boolean) {
    this.filtersAreOpened = force ?? !this.filtersAreOpened;
    this.filtersClick.emit(this.filtersAreOpened);
  }

  openSourceSearch(): void {
    const dialogRef = this._dialog.open(SourceSearchModalComponent);
    dialogRef.componentInstance.botId = this.botId;
    dialogRef.componentInstance.sourceSelected.subscribe((source: TSourceItem) => {
      this._localSourcesService.addItem(this.botId, source);
      dialogRef.close();
    });
  }

  removeSource(source: TSourceItem): void {
    this._localSourcesService.removeItem(this.botId, source);
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
