import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstUpper',
})
export class FirstUpperPipe implements PipeTransform {
  transform(str: string | null): string {
    if (!str) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}
