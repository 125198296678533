import { Injectable } from '@angular/core';
import { BaseBotMessageService } from '@shared/services/bots/partials/base-bot-message-service';
import { MessageAuthor, TSourceDetail } from '@core/interfaces/TMessage';
import { Language } from '@core/interfaces/language';
import { TMessageType } from '@shared/services/bots/types/types';

@Injectable({
  providedIn: 'root',
})
export class BotMessagesService extends BaseBotMessageService {
  public addMessage(
    author: MessageAuthor,
    message: string,
    conversationId?: string,
    messageId?: string,
    lang: Language = 'en',
    type: TMessageType = 'message'
  ) {
    let currentConversation = this.getConversationValue();

    if (!currentConversation) {
      currentConversation = { id: conversationId, messages: [], language: lang, favorite: false };
    }

    if (!currentConversation.id) {
      currentConversation.id = conversationId;
    }

    currentConversation.messages.push({
      date: new Date().toISOString(),
      author,
      sources: [],
      paragraphs: [{ text: message }],
      favorite: false,
      type,
      originalSourceDetails: [],
      _id: messageId || '',
    });
    this.$adaptedBotConversation.next(currentConversation);
  }

  public appendMessageViaAdapter(data: any, sourceDetails?: TSourceDetail[]) {
    this.$adaptedBotConversation.next(this.adapter.adapt(data, this.getConversationValue(), sourceDetails));
  }

  public getConversation() {
    return this.getConversationValue();
  }

  public updateLastMessageId(messageId: string) {
    const currentConversation = this.getConversationValue();
    if (currentConversation) {
      const lastMessage = currentConversation.messages[currentConversation.messages.length - 1];
      if (lastMessage) {
        lastMessage._id = messageId;
      }
      this.$adaptedBotConversation.next(currentConversation);
    }
  }

  public addErrorMessage(message = 'An error occurred. Please try again.') {
    const msg = this.getLastMessage();
    const currentIsWritingStatus = this.isWriting;
    this.toggleIsWritingStatus(false);
    this.addMessage('assistant', message, undefined, undefined, 'en', 'error');
    if (msg) {
      if (currentIsWritingStatus){
        this.addMessage(msg.author, '');
        this.toggleIsWritingStatus(currentIsWritingStatus);
      }
    }
  }
}
