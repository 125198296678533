import { TAdaptedBotConversation, TAdaptedMessage } from '@shared/services/bots/types/types';
import { BehaviorSubject } from 'rxjs';
import { IBotMessageAdapter } from '@shared/services/bots/adapters/IBotMessageAdapter';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { MessageAuthor } from '@core/interfaces/TMessage';
import { DataConversion } from '@shared/services/bots/partials/data-conversion';

export class BaseBotMessageService extends DataConversion {
  private _adapter: IBotMessageAdapter | null = null;
  public isWriting = false;
  public isLoading = false;

  protected $adaptedBotConversation = new BehaviorSubject<TAdaptedBotConversation | null>(null);
  public adaptedBotConversationObservable = this.$adaptedBotConversation.asObservable();

  setAdapter(adapter: IBotMessageAdapter | null) {
    this._adapter = adapter || this._adapter;
    return this;
  }

  get adapter(): IBotMessageAdapter {
    if (this._adapter === null) {
      throw new Error('No adapter set!');
    }
    return this._adapter!;
  }

  toggleIsWritingStatus(isWriting: boolean) {
    this.isWriting = isWriting;
    const currentValue = this.getConversationValue();
    const lastMessage = ArrayUtils.last(currentValue?.messages);
    if (lastMessage) {
      lastMessage.isWriting = isWriting;
      this.$adaptedBotConversation.next(currentValue);
    }
  }

  getLastMessage(): TAdaptedMessage | null {
    const conversation = this.getConversationValue();
    return conversation ? <TAdaptedMessage>ArrayUtils.last(conversation.messages) : null;
  }

  getLastMessageByAuthor(author: MessageAuthor): TAdaptedMessage {
    const conversation = this.getConversationValue()!;
    const msg = <TAdaptedMessage>(
      conversation.messages.filter(message => message.author === author).pop()
    );
    this.$adaptedBotConversation.next(conversation);
    return msg;
  }

  updateLastMessage(author: MessageAuthor, text: string, append = true): void {
    const conversation = this.getConversationValue()!;
    const msg = <TAdaptedMessage>(
      conversation.messages.filter(message => message.author === author).pop()
    );
    const lastPar = ArrayUtils.last(msg.paragraphs);
    if (lastPar?.text) {
      lastPar.text = append ? lastPar.text + text : text;
    }
    this.$adaptedBotConversation.next(conversation);
  }

  updateLastMessageByFiled<T extends keyof TAdaptedMessage>(
    author: MessageAuthor,
    key: T,
    value: TAdaptedMessage[T]
  ) {
    const conversation = this.getConversationValue()!;
    if (!conversation) return;

    const msg = <TAdaptedMessage>(
      conversation.messages.filter(message => message.author === author).pop()
    );
    msg[key] = value;
    this.$adaptedBotConversation.next(conversation);
  }

  updateOriginalContent(originalContent: string) {
    const conversation = this.getConversationValue()!;
    if (!conversation) return;
    conversation.content = originalContent;
    this.$adaptedBotConversation.next(conversation);
  }

  countMessages(): number {
    return this.getConversationValue()?.messages.length ?? 0;
  }

  reset() {
    this.setAdapter(null);
    this.$adaptedBotConversation.next(null);
    return this;
  }
}
