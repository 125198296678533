import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'app-select-items-to-export-modal',
  templateUrl: './select-items-to-export-modal.component.html',
  styleUrls: ['./select-items-to-export-modal.component.scss'],
})
export class SelectItemsToExportModalComponent {
  @Output() selected = new EventEmitter<string>();
  protected selectedAll = false;
  protected selectedItems: { [id: number]: boolean } = {};
  protected includeRefs = true;

  constructor(
    protected botMessageService: BotMessagesService,
    private dialogRef: MatDialogRef<SelectItemsToExportModalComponent>,
  ) {
    this.dialogRef.afterClosed().subscribe(() => {
      this.selectedAll = true;
      this.toggleSelectAll();
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  toggleSelection(index: number, ev: MatCheckboxChange) {
    this.selectedItems[index] = ev.checked;
    this.updateToggleAll();
  }

  protected updateToggleAll() {
    this.selectedAll = this.countSelectedItems() === this.botMessageService.getConversationValue()?.messages.length;
  }

  exportSelected() {
    this.cancel();
    const result = this.botMessageService.getConversationValue()?.messages
      .filter((item, index) => this.selectedItems[index])
      .reduce((acc, message) => acc + this.botMessageService.messageToString(message, this.includeRefs), '');
    this.selected.emit(result);
  }

  countSelectedItems(): number {
    return Object.values(this.selectedItems).filter(value => value).length;
  }

  isValid(): boolean {
    return this.countSelectedItems() > 0;
  }

  toggleSelectAll() {
    this.selectedAll = !this.selectedAll;
    this.botMessageService.getConversationValue()?.messages.map((value, index) => this.selectedItems[index] = this.selectedAll);
  }
}
