<div class="main">
  <div class="d-flex align-items-center form-field-full messages-form">
    <div class="w-full content-container">
      <!-- <app-local-sources [botId]="bot._id"></app-local-sources> -->
      <div class="bot-results-container" *ngIf="botMessageService.countMessages() !== 0">
        <app-bot-results [bot]="bot"></app-bot-results>
        <button
          *ngIf="!isSearching && !isWriting && botMessageService.countMessages()"
          mat-raised-button
          class="m-b-16 w-45"
          color="accent"
          data-cy="regenerate-msg"
          (click)="regenerateMessage()"
        >
          <mat-icon>autorenew</mat-icon>
          Regenerate response
        </button>
      </div>
    </div>
    <app-spinner *ngIf="isSearching" [message]="'Bot is gathering information...'"></app-spinner>

    <app-conversation-input
      [fixedToBottom]="true"
      *ngIf="!hideInput"
      [promptHints]="bot.promptHints || []"
      [filterButton]="allowFilterButton"
      [botId]="bot._id"
      (filtersClick)="openFilters.emit($event)"
      [isWriting]="isWriting"
      (messageSubmitted)="sendMessage($event.message, $event.files, $event.sourceIds)"
      (writingAbort)="stopWriting()"
    ></app-conversation-input>
  </div>
</div>
