import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { TBotItem } from '@core/interfaces/TBotItem';
import { TSearchResponseItem } from '@core/interfaces/TSearch';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { SearchService } from '@shared/services/search.service';
import { SourcesService } from '@shared/services/sources.service';
import { initialPaging } from '@shared/utils/paging';
import { BotConversationAdapter } from '@shared/services/bots/adapters/BotConversationAdapter';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { ConversationsService } from '@shared/services/conversations.service';
import { SourceSearchModalComponent } from '../../components/modals/source-search-modal/source-search-modal.component';

@Component({
  selector: 'app-compare-bot',
  templateUrl: './compare-bot.component.html',
  styleUrls: ['./compare-bot.component.scss'],
})
export class CompareBotComponent implements OnInit, OnDestroy {
  @Input() bot: TBotItem = {} as TBotItem;
  isContentLoading = false;
  isSearchLoading = false;
  sourcesIds: string[] | null = null;
  sources: TSourceItem[] = [];
  content: string | null = null;
  private sourcesIdsChanged = new BehaviorSubject<boolean>(false);
  paging = initialPaging;
  searchResults: TSearchResponseItem<TSourceItem> = {} as TSearchResponseItem<TSourceItem>;
  private _subs: Subscription[] = [];

  public searchForm = this.fb.nonNullable.group({
    query: [''],
  });

  constructor(
    private route: ActivatedRoute,
    private _searchService: SearchService,
    private _sourceService: SourcesService,
    private fb: FormBuilder,
    private botMessageService: BotMessagesService,
    private _conversationService: ConversationsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.botMessageService.reset();
    this.botMessageService.setAdapter(new BotConversationAdapter());

    this.route.queryParams.subscribe(params => {
      this.sourcesIds = params.sources;
      if (this.sourcesIds && params.action === 'compare') {
        this.sourcesIdsChanged.next(true);
      }
    });

    this._subs.push(
      this._searchService.getSearchLoadingObservable().subscribe(res => {
        this.isSearchLoading = res;
      })
    );
    this._subs.push(
      this._searchService.getSearchResponseObservable().subscribe(res => {
        if (res) {
          this.searchResults = res.sources;
          this.paging = { totalResults: res.totalResults ?? 0, page: res.page ?? 1 };
        }
      })
    );
    this._subs.push(
      this.sourcesIdsChanged.subscribe(hasChanged => {
        if (hasChanged) {
          this.getCompareContent();
        }
      })
    );
    this._subs.push(
      this._conversationService.getCurrentConversationObservable().subscribe(res => {
        if (res) {
          this.botMessageService.appendMessageViaAdapter(res);
        }
      })
    );
  }

  public getCompareContent(): void {
    this.isContentLoading = true;
    this.botMessageService.addMessage('user', `Compare of:   \n`);
    this.botMessageService.addMessage('assistant', '');
    this.botMessageService.toggleIsWritingStatus(true);
    if (this.sourcesIds) {
      this.sourcesIds.forEach(sourceId => {
        this._sourceService.getSource(sourceId, (res: TSourceItem) => {
          this.sources.push(res);
          this.botMessageService.updateLastMessage('user', `   - ${res.name}   \n`);
        });
      });
    }

    this._conversationService.getAskBotAsStream(
      {
        botId: this.bot._id,
        body: { sourcesIds: this.sourcesIds! },
      },
      () => {
        this.botMessageService.toggleIsWritingStatus(false);
      }
    );
  }

  public search(): void {
    if (this.searchForm.get('query')?.value !== '') {
      this._searchService.search(
        'sources',
        this.searchForm.get('query')?.value ?? '',
        this.paging.page
      );
    }
  }

  public clearSearch(): void {
    this.searchForm.get('query')?.setValue('');
    this.searchResults = {} as TSearchResponseItem<TSourceItem>;
    this.paging = initialPaging;
  }

  handlePageEvent(e: PageEvent) {
    this.paging.page = e.pageIndex + 1;
    this._searchService.search(
      'sources',
      this.searchForm.get('query')?.value ?? '',
      this.paging.page
    );
  }

  public openSourceSearch(): void {
    const dialogRef = this.dialog.open(SourceSearchModalComponent);
    dialogRef.componentInstance.sourceSelected.subscribe((source: TSourceItem) => {
      this.onSourceSelected(source);
      dialogRef.close();
    });
  }

  private onSourceSelected(source: TSourceItem): void {
    // Your existing source selection logic
  }

  ngOnDestroy(): void {
    this._searchService.clearSearch();
    this.botMessageService.reset();
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
