import { TAdaptedBotConversation } from '../types/types';
import { BaseAdapter } from '@shared/services/bots/adapters/BaseAdapter';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { TSourceMaterial } from '@core/api/materials/types';
import { TConversationStreamData } from '@core/api/conversations/types';
import { MessageParser } from '@shared/services/bots/helpers/messageParser';

export class BotConversationAdapter extends BaseAdapter {
  private lastProcessedChunk = '';

  adapt(
    data: TConversationStreamData,
    currentValue: TAdaptedBotConversation,
    sourcesDetails: TSourceDetail[]
  ): TAdaptedBotConversation {
    if (!currentValue) {
      currentValue = { messages: [], favorite: false, type: 'conversation' };
    }

    currentValue.type = 'conversation';

    if (data.type === 'content') {
      currentValue.content = data.data;
      return currentValue;
    }

    let lastMessage = ArrayUtils.last(currentValue?.messages);
    if (!lastMessage) {
      lastMessage = {
        date: new Date().toISOString(),
        author: 'assistant',
        sources: [],
        paragraphs: [],
        favorite: false,
        type: 'message',
        originalSourceDetails: [],
        _id: '',
      };
      currentValue.messages.push(lastMessage);
    }

    let lastParagraph = ArrayUtils.last(lastMessage.paragraphs);
    if (!lastParagraph) {
      lastParagraph = { text: '', materials: [], references: [] };
      lastMessage.paragraphs.push(lastParagraph);
    }

    const messageText = data.type === 'message' ? data.data as string : '';
    const material = data.type === 'material' ? data.data as TSourceMaterial : null;

    switch (data.type) {
      case 'message':
        if (messageText && messageText !== this.lastProcessedChunk) {
          const overlap = this.findOverlap(this.lastProcessedChunk, messageText);
          const newText = overlap > 0 ? messageText.slice(overlap) : messageText;

          lastParagraph.text += newText;
          this.lastProcessedChunk = messageText;
        }
        break;

      case 'material':
        if (!lastParagraph.materials) {
          lastParagraph.materials = [];
        }
        if (material && !lastParagraph.materials.some(m => m._id === material._id)) {
          lastParagraph.materials.push(material);
        }
        break;

      case 'message_id':
        lastMessage._id = data.data as string;
        this.lastProcessedChunk = '';
        if (
          lastParagraph.text === '' &&
          (!lastParagraph.materials?.length) &&
          (!lastParagraph.references?.length)
        ) {
          lastMessage.paragraphs.pop();
        }
        break;

      case 'sourceDetails':
        if (sourcesDetails?.length) {
          lastMessage.sources = MessageParser.filterSources(lastMessage, sourcesDetails);
          lastMessage.originalSourceDetails = sourcesDetails;
        }
        break;
    }

    if (sourcesDetails?.length) {
      lastMessage.sources = MessageParser.filterSources(lastMessage, sourcesDetails);
      lastMessage.originalSourceDetails = sourcesDetails;
    }

    return currentValue;
  }

  private findOverlap(str1: string, str2: string): number {
    if (!str1 || !str2) return 0;

    let overlap = 0;
    const maxOverlap = Math.min(str1.length, str2.length);

    for (let i = 1; i <= maxOverlap; i++) {
      if (str1.slice(-i) === str2.slice(0, i)) {
        overlap = i;
      }
    }

    return overlap;
  }
}



