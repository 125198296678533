<span [matTooltip]="tooltip">
  <button
    mat-icon-button
    [color]="isInList ? 'warn' : 'primary'"
    class="action-button"
    (click)="addToSourceList()"
    [disabled]="isDisabled"
  >
    <mat-icon>{{ isInList ? 'remove' : 'add' }}</mat-icon>
  </button>
</span> 