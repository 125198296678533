<span [matTooltip]="tooltip"
      [matTooltipPosition]="'above'"
      *ngIf="source"
>
  <button
    mat-raised-button
    [color]="isInList ? 'warn' : 'primary'"
    class="action-button"
    (click)="addToCompareList()"
    [disabled]="isDisabled"
  >
    {{ isInList ? 'Remove from action box' : 'Add to action box' }}
  </button>
</span>
