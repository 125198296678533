import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

import { TSearchResponseItem } from '@core/interfaces/TSearch';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { SearchService } from '@shared/services/search.service';
import { LocalSourcesService } from '@shared/services/local-sources.service';
import { initialPaging } from '@shared/utils/paging';

@Component({
  selector: 'app-source-search-modal',
  templateUrl: './source-search-modal.component.html',
  styleUrls: ['./source-search-modal.component.scss']
})
export class SourceSearchModalComponent implements OnInit, OnDestroy {
  @Input() botId!: string;
  @Output() sourceSelected = new EventEmitter<TSourceItem>();
  @Output() sourceAdded = new EventEmitter<void>();

  isSearchLoading = false;
  private _subs: Subscription[] = [];
  paging = initialPaging;
  searchResults: TSearchResponseItem<TSourceItem> = {} as TSearchResponseItem<TSourceItem>;

  public searchForm = this.fb.nonNullable.group({
    query: [''],
  });

  constructor(
    private _searchService: SearchService,
    private fb: FormBuilder,
    private _localSourcesService: LocalSourcesService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._searchService.getSearchLoadingObservable().subscribe(res => {
        this.isSearchLoading = res;
      })
    );

    this._subs.push(
      this._searchService.getSearchResponseObservable().subscribe(res => {
        if (res) {
          this.searchResults = res.sources;
          this.paging = { totalResults: res.totalResults ?? 0, page: res.page ?? 1 };
        }
      })
    );
  }

  public search(): void {
    if (this.searchForm.get('query')?.value !== '') {
      this._searchService.search(
        'sources',
        this.searchForm.get('query')?.value ?? '',
        this.paging.page
      );
    }
  }

  public clearSearch(): void {
    this.searchForm.get('query')?.setValue('');
    this.searchResults = {} as TSearchResponseItem<TSourceItem>;
    this.paging = initialPaging;
  }

  handlePageEvent(e: PageEvent) {
    this.paging.page = e.pageIndex + 1;
    this._searchService.search(
      'sources',
      this.searchForm.get('query')?.value ?? '',
      this.paging.page
    );
  }

  onSourceClick(source: TSourceItem): void {
    this.sourceSelected.emit(source);
  }

  onSourceAdded(source: TSourceItem): void {
    // Don't emit source for selection, just notify about addition
    this.sourceAdded.emit();
  }

  isSourceAdded(source: TSourceItem): boolean {
    return this._localSourcesService.isItemInList(this.botId, source._id);
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
} 