import { Component, OnInit } from '@angular/core';
import { ThemeTypes } from '@core';
import { BrowserStorageService } from '@shared/services/browser-storage.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss'],
})
export class ThemeToggleComponent implements OnInit {
  theme: ThemeTypes = 'dark';
  brandClass = environment?.brand ? `brand-${environment.brand}` : '';

  constructor(private storageService: BrowserStorageService) {}

  ngOnInit() {
    this.setInitialTheme();
  }

  setInitialTheme() {
    const savedTheme = this.storageService.get('theme') as ThemeTypes;
    if (savedTheme) {
      this.theme = savedTheme;
      this.applyTheme();
    } else {
      this.toggleTheme();
    }
  }

  toggleTheme() {
    this.theme = this.theme === 'dark' ? 'light' : 'dark';
    this.storageService.put('theme', this.theme);
    this.applyTheme();
  }

  applyTheme() {
    document.documentElement.classList.remove('theme-dark', 'theme-light');
    document.documentElement.classList.add(`theme-${this.theme}`);
    if (this.brandClass) {
      document.documentElement.classList.add(this.brandClass);
    }
  }
}
