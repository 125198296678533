<div>
  <div *ngIf="!this.sourcesIds">
    <div class="gingr-input-wrapper gingr-input-wrapper--with-form search-wrapper">
      <button mat-raised-button color="accent" (click)="openSourceSearch()">
        Search for sources
      </button>
    </div>
  </div>
  <app-bot-results></app-bot-results>
</div>
