import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BotsService } from '@shared/services/bots.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TBotItem } from '@core/interfaces/TBotItem';

type TBotItemWithSelected = Pick<TBotItem, keyof TBotItem> & { selected?: boolean };
@Component({
  selector: 'app-bot-selector',
  templateUrl: './bot-selector.component.html',
  styleUrls: ['./bot-selector.component.scss'],
})
export class BotSelectorComponent implements OnInit, OnDestroy {
  constructor(
    private _botsService: BotsService,
    private router: Router,
    private _cdr: ChangeDetectorRef
  ) {}

  protected bots: TBotItemWithSelected[] = [];
  protected _subs: Subscription[] = [];
  @Output() botSelected = new EventEmitter<TBotItem>();

  ngOnInit() {
    this.getBots();
    this._botsService.fetchBots();
  }

  selectBot(id: string) {
    this.bots.forEach(bot => {
      bot.selected = bot._id === id;
    });
    const selectedBot = this.bots.find(bot => bot._id === id);
    if (selectedBot) {
      this.botSelected.emit(selectedBot);
    }
  }

  getBots() {
    this._subs.push(
      this._botsService.getBotsObservable().subscribe(bots => {
        this.bots = [];
        bots.forEach(bot => {
          if (bot.showOnDashboard) {
            this.bots.push(bot);
          }
        });
        if (this.bots.length > 0) {
          this.selectBot(this.bots[0]._id);
        }
        this._cdr.detectChanges();
      })
    );
  }

  goToBots() {
    this.router.navigate(['/bots']);
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
