import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TBotItem } from '@core/interfaces/TBotItem';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { BotConversationAdapter } from '@shared/services/bots/adapters/BotConversationAdapter';

import { InitStreamConversationBody } from '@core/api/conversations/types';
import { ExportToSelectorComponent } from '@shared/components/export-to-selector/export-to-selector.component';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { ConversationsService } from '@shared/services/conversations.service';
import { SourcesService } from '@shared/services/sources.service';
import { TalkingAvatarService } from '../talking-avatar/talking-avatar.service';
import { ReferenceAdapter } from '@shared/services/bots/adapters/ReferenceAdapter';
import { TAdaptedParagraph } from '@shared/services/bots/types/types';
import { ResultsService } from '@shared/services/results.service';
import { BotResultsAdapter } from '@shared/services/bots/adapters/BotResultsAdapter';
import { LocalSourcesService } from '@shared/services/local-sources.service';

@Component({
  selector: 'app-conversation-bot',
  templateUrl: './conversation-bot.component.html',
  styleUrls: ['./conversation-bot.component.scss'],
})
export class ConversationBotComponent implements OnInit, OnChanges, OnDestroy {
  @Input() bot: TBotItem = {} as TBotItem;
  @Input() selectedProjectId: string | undefined;
  @Input() selectedCountries: string[] | undefined;
  @Input() selectedDateStart: string | undefined;
  @Input() selectedDateEnd: string | undefined;
  @Input() hideInput = false;
  @Input() allowFilterButton = true;
  @Output() openFilters = new EventEmitter<boolean>();
  @ViewChild('exportSelector') exportSelector: ExportToSelectorComponent | undefined;
  private _subs: Subscription[] = [];
  private sourceIdsChanged = new BehaviorSubject<boolean>(false);
  private sourcesIdsChanged = new BehaviorSubject<boolean>(false);
  sourceId = '';
  selectedSource: TSourceItem | null = null;
  selectedLocalSources: TSourceItem[] = [];

  isStartingConversation = true;
  isWriting = false;
  isSearching = false;
  currentConversationId: string | null = null;
  sourceDetails: TSourceDetail[] = [];
  private messageFromQueryParams: string | undefined;
  private queryParams: Params | undefined = undefined;
  private compareAndSum: {
    message: string;
    sourcesIds: string[];
  } = {
    message: '',
    sourcesIds: [],
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _conversationService: ConversationsService,
    protected botMessageService: BotMessagesService,
    private _sourceService: SourcesService,
    private _talkingAvatarService: TalkingAvatarService,
    private _resultsService: ResultsService,
    private _localSourcesService: LocalSourcesService
  ) {
    this.botMessageService.setAdapter(new BotConversationAdapter());
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
      if (params.source && !params.sources) {
        this.sourceId = params.source;
        this.sourceIdsChanged.next(true);
      }
      if (params.sources && params.action) {
        this.compareAndSum.sourcesIds = Array.isArray(params.sources)
          ? params.sources
          : [params.sources];
        this.compareAndSum.message = params.action;
        this.sourcesIdsChanged.next(true);
        this.sourceId = '';
      }
      if (params.message) {
        this.messageFromQueryParams = params.message;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { message: null },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.sendMessageFromQueryParams();
  }

  private resetState(): void {
    this.botMessageService.reset();
    this.stopWriting();
    this.currentConversationId = null;
    this.sourceDetails = [];
    this.isStartingConversation = true;
    this.isWriting = false;
    this.isSearching = false;
    // Clear source-related state
    this.sourceId = '';
    this.selectedSource = null;
    this.compareAndSum = {
      message: '',
      sourcesIds: [],
    };
    // Ensure we don't have any pending requests
    this._conversationService.cancelStreamConversation();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.bot?.previousValue !== changes.bot?.currentValue) {
      this.resetState();

      // Reinitialize subscriptions for the new bot
      this.initializeSubscriptions();

      // Reset adapter for new bot
      this.botMessageService.setAdapter(new BotConversationAdapter());
    }
  }

  private initializeSubscriptions(): void {
    if (!this.bot?._id) return;  // Safety check

    // Conversation service subscriptions
    this._subs.push(
      this._conversationService.getIsSearchingObservable().subscribe(r => {
        this.isSearching = r;
      })
    );

    this._subs.push(
      this._conversationService.getIsWritingObservable().subscribe(isWriting => {
        this.isWriting = isWriting;
        this.botMessageService.toggleIsWritingStatus(isWriting);
      })
    );

    this._subs.push(
      this._conversationService.getConversationIdObservable().subscribe(r => {
        this.currentConversationId = r;
      })
    );

    this._subs.push(
      this._conversationService.getCurrentConversationObservable().subscribe(res => {
        if (res) {
          if (res.type === 'message_id') {
            this.botMessageService.updateLastMessageId(res.data);
          }
          this.botMessageService.appendMessageViaAdapter(res, this.sourceDetails);
        }
      })
    );

    this._subs.push(
      this._conversationService.getErrorOccurredAsObservable().subscribe(error => {
        if (error) {
          this.botMessageService.addErrorMessage(error);
        }
      })
    );

    this._subs.push(
      this._conversationService.getSourceDetailsObservable().subscribe(res => {
        if (res) {
          this.sourceDetails = res;
        }
      })
    );

    // Source-related subscriptions
    this._subs.push(
      this.sourceIdsChanged.subscribe(hasChanged => {
        if (hasChanged && this.sourceId) {  // Safety check
          this._sourceService.getSource(this.sourceId, (res: TSourceItem) => {
            this.selectedSource = res;
          });
        }
      })
    );

    this._subs.push(
      this.sourcesIdsChanged.subscribe(hasChanged => {
        if (hasChanged && this.compareAndSum.sourcesIds.length > 0) {  // Safety check
          this.sendMessage(this.compareAndSum.message, [], this.compareAndSum.sourcesIds);
        }
      })
    );

    // Subscribe to local sources changes for current bot
    this._subs.push(
      this._localSourcesService.getItems(this.bot._id).subscribe(sources => {
        this.selectedLocalSources = sources;
      })
    );
  }

  public addUserMessage(message: string | undefined): void {
    if (message) {
      this.botMessageService.addMessage('user', message);
    }
  }

  public addBotMessage(conversationId: string): void {
    this.botMessageService.addMessage('assistant', '', conversationId);
  }

  async sendMessage(message: string, files: File[], sourceIds: string[] = []) {
    if (!message && !files.length) return;

    this.isWriting = true;
    this.hideInput = true;

    const formData = new FormData();
    formData.append('message', message);
    formData.append('botId', this.bot._id);
    formData.append('sourceIds', JSON.stringify(sourceIds));

    if (files.length) {
      files.forEach(file => {
        formData.append('files', file);
      });
    }

    this._talkingAvatarService.init();
    const loadReferences = !!message;
    this.addUserMessage(message);
    if (this.isStartingConversation) {
      this.isStartingConversation = false;
      const initStreamBody: InitStreamConversationBody = {
        question: message,
        project_id: this.selectedProjectId,
        countries: this.selectedCountries,
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd,
      };

      // First priority: use local sources if available
      if (this.selectedLocalSources.length > 0) {
        initStreamBody.sourcesIds = this.selectedLocalSources.map(source => source._id);
      }
      // Second priority: use source from URL if available
      else if (this.sourceId) {
        initStreamBody.source_id = this.sourceId;
      }
      // Third priority: use sources from compare/summary if available
      else if (this.compareAndSum.sourcesIds.length > 0) {
        initStreamBody.sourcesIds = this.compareAndSum.sourcesIds;
      }

      this._conversationService.initStreamConversation(
        this.bot._id,
        initStreamBody,
        files,
        response => {
          this.botMessageService.updateLastMessageId(response.message_id);
          this.botMessageService.updateLastMessageByFiled('user', 'files', response.uploadedFiles);
          if (response.content) {
            this.botMessageService.updateOriginalContent(response.content);
          }
          this.getConversation(response.conversation_id, loadReferences);
        }
      );
    } else {
      this._conversationService.addQuestionToConversation(
        this.bot._id,
        {
          question: message ?? '',
          conversation_id: this.currentConversationId as string,
        },
        (conversationId, messageId) => {
          this.botMessageService.updateLastMessageId(messageId);
          this.getConversation(conversationId, loadReferences);
        }
      );
    }
  }

  private getConversation(conversationId: string | undefined, loadReferences = true): void {
    if (!conversationId) return;
    this.addBotMessage(conversationId);
    this.botMessageService.setAdapter(new BotConversationAdapter());
    this._conversationService.getConversationAsStream(
      {
        botId: this.bot._id,
        conversationId,
      },
      () => {
        this.generateAvatarMessage();
        if (loadReferences) {
          this.getReferences();
        }
      }
    );
  }

  protected generateAvatarMessage(): void {
    const msg = this.botMessageService.getLastMessageByAuthor('assistant');
    const txt = this.botMessageService.getCopyMessage(msg);
    this._talkingAvatarService.setIds(this.currentConversationId as string, msg._id);
    this._talkingAvatarService.sendQuestion(txt);
  }

  protected getReferences() {
    try {
      this.botMessageService.toggleIsWritingStatus(true);

      const msg = this.botMessageService.getLastMessageByAuthor('assistant');
      const newParagraphs: TAdaptedParagraph[] = [];
      msg.paragraphs.forEach(paragraph => {
        const sentences = paragraph.text.split(/\n\n/);
        sentences.forEach((sentence, index) => {
          const newParagraph: TAdaptedParagraph = {
            text: sentence + (index < sentences.length - 1 ? ' ' : ''),
            materials: index === sentences.length - 1 ? paragraph.materials : undefined,
            references: index === sentences.length - 1 ? paragraph.references : undefined,
            quizQuestions: index === sentences.length - 1 ? paragraph.quizQuestions : undefined,
          };
          newParagraphs.push(newParagraph);
        });
      });

      msg.paragraphs = newParagraphs;

      this._conversationService
        .getReferences(
          this.currentConversationId as string,
          msg._id,
          newParagraphs.map(p => p.text)
        )
        .subscribe(value => {
          this.botMessageService.setAdapter(new ReferenceAdapter());
          this.botMessageService.appendMessageViaAdapter(value);
          this.botMessageService.toggleIsWritingStatus(false);
        });
    } catch (e) {
      this.botMessageService.toggleIsWritingStatus(false);
      console.info('There was an error getting references', e);
    }
  }

  regenerateMessage(): void {
    const lastMessage = this.botMessageService.getLastMessageByAuthor('user').paragraphs[0].text;
    this.sendMessage(lastMessage, [], []);
  }

  stopWriting(): void {
    this._conversationService.cancelStreamConversation();
  }

  async sendMessageFromQueryParams(): Promise<void> {
    if (this.queryParams?.cID) {
      this.currentConversationId = this.queryParams.cID;
      this.isStartingConversation = false;
      await this.loadMessagesFromResults();
    }

    if (this.messageFromQueryParams) {
      this.sendMessage(this.messageFromQueryParams, [], []);
    }
  }

  private loadMessagesFromResults(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._resultsService.fetchResultConversation(this.currentConversationId!, res => {
        try {
          this.botMessageService.setAdapter(new BotResultsAdapter());
          this.botMessageService.appendMessageViaAdapter(res);
          this.botMessageService.isLoading = false;
          this.botMessageService.setAdapter(new BotConversationAdapter());
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public onSourceSelected(source: TSourceItem): void {
    this.selectedSource = source;
    this.sourceId = source._id;
    this.sourceIdsChanged.next(true);
  }
}
