import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TSourceItem } from '@core/interfaces/TSourceItem';

import { BotsService } from '@shared/services/bots.service';
import { BrowserStorageService } from '@shared/services/browser-storage.service';
import { CommonService } from '@shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class MultiSummaryService {
  private readonly storageKey = 'multi_summary';
  public readonly maxListItems: number = 5;
  private primarySummaryBotId: string | undefined | null;
  private primaryCompareBotId: string | undefined | null;
  private primaryConversationBotId: string | undefined | null;
  private _itemsToCompare: TSourceItem[] = [];
  private itemsToCompareSubject = new BehaviorSubject<TSourceItem[]>(this._itemsToCompare);
  private isVisibleSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private _commonService: CommonService,
    private _storage: BrowserStorageService,
    private _botsService: BotsService
  ) {
    this._itemsToCompare = this._storage.get<TSourceItem[]>(this.storageKey) ?? [];
    this.emitChangesToSubject();

    this._botsService.getPrimarySummaryBotIdObservable().subscribe(res => {
      this.primarySummaryBotId = res;
    });
    this._botsService.getPrimarySummaryBot();

    this._botsService.getPrimaryCompareBotIdObservable().subscribe(res => {
      this.primaryCompareBotId = res;
    });
    this._botsService.getPrimaryCompareBot();

    this._botsService.getPrimaryConversationBotIdObservable().subscribe(res => {
      this.primaryConversationBotId = res;
    });
    this._botsService.getPrimaryConversationBot();
  }

  addItem(item: TSourceItem) {
    if (!this.isValid(item)) return;

    this._itemsToCompare.push(item);
    this.emitChangesToSubject();
    this._storage.put(this.storageKey, this._itemsToCompare);
  }

  removeItem(item: TSourceItem) {
    this._itemsToCompare = this._itemsToCompare.filter(it => it._id !== item._id);
    this.emitChangesToSubject();
    this._storage.put(this.storageKey, this._itemsToCompare);
  }

  protected isValid(element: TSourceItem) {
    if (this._itemsToCompare.length >= this.maxListItems) {
      this._commonService.openSnackBar(
        `Maximum items of ${this.maxListItems} reached please remove some to add more`
      );
      return false;
    }

    if (this._itemsToCompare.some(i => i._id === element._id)) {
      this._commonService.openSnackBar('Element exists on the list');
      return false;
    }
    return true;
  }

  getItems() {
    return this.itemsToCompareSubject.asObservable();
  }

  deleteAll() {
    this._itemsToCompare = [];
    this._storage.remove(this.storageKey);
    this.emitChangesToSubject();
  }

  private emitChangesToSubject(): void {
    this.itemsToCompareSubject.next(this._itemsToCompare);
    this.updateVisibility(this._itemsToCompare);
  }

  public getSummaryBotId() {
    return this.primarySummaryBotId;
  }

  public getCompareBotId() {
    return this.primaryCompareBotId;
  }

  public getConversationBotId() {
    return this.primaryConversationBotId;
  }

  public getVisibilityState() {
    return this.isVisibleSubject.asObservable();
  }

  private updateVisibility(items: TSourceItem[]) {
    this.isVisibleSubject.next(items.length > 0);
  }

  public isItemInList(id: string): boolean {
    return this._itemsToCompare.some(item => item._id === id);
  }
}
