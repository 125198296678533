import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { MultiSummaryService } from '@shared/services/multi-summary.service';
import { ClickToAdd, NoContent, NoPrimaryCompareBot, NoPrimarySummaryBot } from '../../../../constants/messages';

@Component({
  selector: 'app-add-to-list-button',
  templateUrl: './add-to-list-button.component.html',
  styleUrls: ['./add-to-list-button.component.scss'],
})
export class AddToListButtonComponent {
  protected tooltip = '';
  private _source: TSourceItem | undefined;

  @Input({ required: true }) set source(source: TSourceItem | undefined) {
    this._source = source;
    this.updateTooltipMessages();
  }

  get source() {
    return this._source;
  }

  @Output() sourceAdded = new EventEmitter<TSourceItem>();

  constructor(private _multiselectService: MultiSummaryService) {
  }

  get isDisabled(): boolean {
    return !this.source?.content;
  }

  get isInList(): boolean {
    return this.source ? this._multiselectService.isItemInList(this.source._id) : false;
  }

  public addToCompareList() {
    if (!this.source) return;
    
    if (this.isInList) {
      this._multiselectService.removeItem(this.source);
    } else {
      this._multiselectService.addItem(this.source);
      this.sourceAdded.emit(this.source);
    }
  }

  public updateTooltipMessages(): void {
    const conditions = [
      { condition: !this._multiselectService.getSummaryBotId(), message: NoPrimarySummaryBot },
      { condition: !this._multiselectService.getCompareBotId(), message: NoPrimaryCompareBot },
      { condition: !this.source?.content, message: NoContent },
      { condition: this.source?.content, message: ClickToAdd }
    ];

    this.tooltip = conditions
      .filter(item => item.condition)
      .map(item => item.message)
      .join('. ');
  }

}
