import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { LocalSourcesService } from '@shared/services/local-sources.service';

@Component({
  selector: 'app-local-source-button',
  templateUrl: './local-source-button.component.html'
})
export class LocalSourceButtonComponent {
  private _source: TSourceItem | undefined;
  @Input() botId!: string;

  @Input() set source(source: TSourceItem | undefined) {
    this._source = source;
  }

  get source() {
    return this._source;
  }

  @Output() sourceAdded = new EventEmitter<TSourceItem>();

  constructor(private _localSourcesService: LocalSourcesService) {}

  get isDisabled(): boolean {
    return !this.source?.content;
  }

  get isInList(): boolean {
    return this.source ? this._localSourcesService.isItemInList(this.botId, this.source._id) : false;
  }

  get tooltip(): string {
    return this.isInList ? 'Remove from sources' : 'Add to sources';
  }

  public addToSourceList() {
    if (!this.source) return;
    
    if (this.isInList) {
      this._localSourcesService.removeItem(this.botId, this.source);
    } else {
      if (this._localSourcesService.addItem(this.botId, this.source)) {
        this.sourceAdded.emit(this.source);
      }
    }
  }
} 
