<div class="modal-container">
  <h2 mat-dialog-title>Search Sources</h2>
  <div class="search-container">
    <div class="search-wrapper">
      <form [formGroup]="searchForm" (ngSubmit)="search()">
        <input
          matInput
          placeholder="Type to search sources..."
          formControlName="query"
          autocomplete="off"
          autofocus
        />
      </form>
      <div class="search-buttons">
        <button mat-icon-button (click)="clearSearch()" [disabled]="!searchForm.get('query')?.value">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="search()" [disabled]="!searchForm.get('query')?.value">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </div>
  
  <mat-dialog-content>
    <div *ngIf="isSearchLoading" class="d-flex align-items-center w-full">
      <mat-progress-spinner class="spinner" [color]="'accent'" [mode]="'indeterminate'" [diameter]="32">
      </mat-progress-spinner>
    </div>

    <div class="results-section" *ngIf="!isSearchLoading">
      <div *ngIf="searchResults.total === 0" class="no-results">
        <mat-icon>search_off</mat-icon>
        <p>No results found</p>
      </div>

      <div *ngIf="searchResults.total > 0">
        <mat-table [dataSource]="searchResults.data" #table>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Source</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="d-flex align-items-center fs-14 clickable" (click)="onSourceClick(row)">
                <div class="d-flex flex-col">
                  <span class="source-name">{{ row.name }}</span>
                  <span class="reference">{{ row.references }}</span>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="justify-content-center max-width-20">
              Actions
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="justify-content-center max-width-20">
              <app-local-source-button [source]="row" [botId]="botId" (sourceAdded)="onSourceAdded($event)"></app-local-source-button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['name', 'actions']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['name', 'actions']" 
                   [class.selected]="isSourceAdded(row)">
          </mat-row>
        </mat-table>

        <mat-paginator
          #paginator
          (page)="handlePageEvent($event)"
          [length]="searchResults.total"
          [pageSize]="10"
          [pageIndex]="paging.page - 1"
          aria-label="Select page"
          class="mat-elevation-z0"
        >
        </mat-paginator>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary">Cancel</button>
  </mat-dialog-actions>
</div> 