import { Component, Inject, OnInit } from '@angular/core';

import { environment } from '@env/environment';

import { StringUtils } from '@shared/utils/stringUtils';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styles: [
    `
      .brand-logo {
        max-height: 60px;
        object-fit: contain;
      }
    `,
  ],
})
export class BrandingComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  protected brandLogoPath = StringUtils.brandResource('logo');
  protected brandLogo: string | undefined;
  protected brandLogoPathLight = StringUtils.brandResource('logoLight');
  protected brandLogoWidth = environment.logoWidthPx ?? '160px';
  get isDarkMode(): boolean {
    return this.document.documentElement.classList.contains('theme-dark');
  }

  ngOnInit(): void {
    this.brandLogo =  this.isDarkMode ? this.brandLogoPath : this.brandLogoPathLight;
  }

  // get brandLogo(): string {
  //
  // }


}
