<div class="p-48 centered-content" *ngIf="!conversationBegan">
  <div class="centered-text">
    <img src="assets/brands/prod/sidebarLogo.png" alt="OneRay logo" class="logo">
    <h1>{{ getGreeting() }}!</h1>
    <p *ngIf="dashboardContent" [innerHTML]="dashboardContent"></p>

  </div>
  <app-conversation-input
    [isExtended]="true"
    [isDisabled]="!selectedBot"
    [promptHints]="selectedBot?.promptHints || []"
    [botId]="selectedBot?._id || ''"
    (messageSubmitted)="submit($event)"
  ></app-conversation-input>
  <app-bot-selector (botSelected)="selectedBot = $event"></app-bot-selector>
</div>


<div [hidden]="!conversationBegan">
  <div *ngIf="selectedBot">
<!--    <app-bots-content-->
<!--    [selectedBot]="selectedBot"-->
<!--    >-->
<!--    </app-bots-content>-->
    <app-conversation-bot
      [allowFilterButton]="false"
      [hideInput]="!conversationBegan"
      [bot]="selectedBot"
    ></app-conversation-bot>
  </div>
</div>
